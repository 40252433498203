import { createContext, useContext } from 'react';
import { FormInstance } from 'antd';
import { ICheckout, ICheckoutItem } from '../../interfaces/ICheckout';
import { ICustomer } from '../../interfaces/ICustomer';

type FormContext = {
  checkout: ICheckout;
  isLoading?: boolean;
  form: FormInstance;
  isPaying?: boolean;
  modeAs: 'checkout' | 'transaction' | 'preview';
  items: ICheckout['items'];
  addItems: (items: ICheckout['items']) => void;
  removeItems: (items: ICheckout['items']) => void;
  getTotal(withoutBump?: boolean): number;
  isCached: boolean;
  isOCB?: boolean;
  ocbData?: {
    customer: ICustomer;
    payments: any[];
  };
  disableOCB: () => void;
  isRecurrence: boolean;
  firstItem: ICheckoutItem;
  isForeigner: boolean;
  setForeignerMode: (enabled: boolean) => void;
  canChangeForeigner: boolean;
  paymentMethods: string[];
  setPaymentMethods: (methods: string[]) => void;
};

const FormContext = createContext<FormContext>({} as FormContext);

export function useForm() {
  const context = useContext(FormContext);

  if (context === undefined) {
    throw new Error('useFiltersContext must be used within a FiltersProvider');
  }

  return context;
}

const FormProvider = FormContext.Provider;

export default FormProvider;
