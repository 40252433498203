import styled from '@emotion/styled';
import { ReactNode, memo } from 'react';
import { IStyledProp } from '../../interfaces/IStyledProp';
import { Typography } from 'antd';

const { Link } = Typography;

interface IProps extends IStyledProp {
    companyName: string | ReactNode;
    companyEmail: string | ReactNode;
    productName: string | ReactNode;
}

const Terms = memo<IProps>(({ companyName, companyEmail, productName }) => {

    return (
        <div>
            <p className='my-5'>
                Ao finalizar a compra, você declara que leu e está de acordo com todas as regras dos{' '}
                <Link href="https://routerfy.com/termosuso" target="_blank">Termos de Uso</Link>{' '}
                da <strong>{companyName}</strong>;
            </p>
            <p className='my-5'>Este produto foi criado por <strong>{companyName}</strong> . Para questões relacionadas especificamente ao conteúdo desta compra, por favor, entre em contato através do email <strong>{companyEmail}</strong>;</p>
            <p className='my-5'>Para problemas e/ou dúvidas sobre a compra, como opções de pagamento, acesso ao produto, reembolso, cancelamento e suporte em geral, por favor, entre em contato através do e-mail <strong>{companyEmail}</strong>;</p>
            <p className='my-5'>Há um período de garantia de 30 dias para solicitar cancelamento da compra caso seja identificado algum defeito/inconsistência;</p>
            <p className='my-5'>Esta compra aparecerá no seu extrato ou fatura do cartão como sendo realizada na loja <strong>{productName}</strong>. Contudo, ressaltamos que se trata de uma mera identificação na fatura ou extrato do cartão;
            </p>
            <p className='my-5'>Fique ciente que o não pagamento de qualquer parcela acarretará o bloqueio do acesso ao produto.</p>
        </div>
    )
});

export default styled(Terms)``;

