import { Form, Row, Col, message } from 'antd';
import type { SearchProps } from 'antd/es/input/Search';
import Input from '../../../../Input';
import getCEP from '../api';
import { HomeOutlined } from '@ant-design/icons';
import { useForm } from '../../../context';
import { useEffect } from 'react';
import { cepMask } from '../../../helper';

interface IProps {
  name?: string[];
}

const NationalAddress = ({ name }: IProps) => {
  name = name ?? [];

  const { form } = useForm();

  const cep = Form.useWatch([...name, 'postalCode'], form);

  const onSearch: SearchProps['onSearch'] = async (value) => {
    if (value === '') {
      return;
    }

    try {
      const response = await getCEP.get(`${value}/json`);

      if (response.data.erro) {
        message.error('CEP não encontrado');
        return;
      }

      const fieldName = [...(name as string[])];
      form.setFieldValue([...fieldName, 'state'], response.data?.uf ?? '');
      form.setFieldValue([...fieldName, 'street'], response.data?.logradouro ?? '');
      form.setFieldValue([...fieldName, 'complement'], response.data?.complemento ?? '');
      form.setFieldValue([...fieldName, 'neighborhood'], response.data?.bairro ?? '');
      form.setFieldValue([...fieldName, 'city'], response.data?.localidade ?? '');
    } catch (error) {
      message.error('CEP não encontrado');
    }

    return;
  };

  useEffect(() => {
    if (cep?.length != 9) {
      return;
    }

    onSearch(cep);
  }, [cep]);

  return (
    <>
      <Row gutter={10}>
        <Col span={12}>
          <Form.Item
            label="CEP"
            name={[...name, 'postalCode']}
            rules={[
              {
                required: true,
                message: 'Por favor, digite o seu CEP!',
              },
            ]}
          >
            <Input.Search
              onChange={(e) => {
                const fixed = cepMask.apply(e.target.value);
                form.setFieldValue([...(name as string[]), 'postalCode'], fixed);
              }}
              placeholder="Digite seu CEP"
              onSearch={onSearch}
              prefix={<HomeOutlined style={{ color: '#B6BBC2' }} />}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Estado"
            name={[...name, 'state']}
            rules={[
              {
                required: true,
                message: 'Por favor, digite o seu estado!',
              },
            ]}
          >
            <Input placeholder="Digite seu estado" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Endereço"
        name={[...name, 'street']}
        rules={[
          {
            required: true,
            message: 'Por favor, digite seu endereço!',
          },
        ]}
      >
        <Input placeholder="Digite seu endereço" />
      </Form.Item>

      <Row gutter={10}>
        <Col span={6}>
          <Form.Item
            label="Número"
            name={[...name, 'number']}
            rules={[{ required: true, message: 'Por favor, digite o número do endereço!' }]}
          >
            <Input placeholder="Digite o número" />
          </Form.Item>
        </Col>
        <Col span={18}>
          <Form.Item label="Complemento (opcional)" name={[...name, 'complement']}>
            <Input placeholder="Digite um complemento" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Bairro"
        name={[...name, 'neighborhood']}
        rules={[{ required: true, message: 'Por favor, digite o bairro!' }]}
      >
        <Input placeholder="Digite seu bairro" />
      </Form.Item>

      <Form.Item
        label="Cidade"
        name={[...name, 'city']}
        rules={[{ required: true, message: 'Por favor, digite a cidade!' }]}
      >
        <Input placeholder="Digite sua cidade" />
      </Form.Item>
    </>
  );
};

export default NationalAddress;
