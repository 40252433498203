import { Form, Row, Col, Tooltip } from 'antd';
import Input from '../../../Input';
import imgcvv from '../../../../assets/cvv.png';

import {
  CreditCardOutlined,
  UserOutlined,
  CalendarOutlined,
  KeyOutlined,
  QuestionCircleFilled,
} from '@ant-design/icons';
import { useForm } from '../../context';
import { checkLuhn, creditCardMask, dateMask } from '../../helper';
import Installments from './Installments';

interface IProps {
  name?: (string | number)[];
}

const CreditCard = ({ name }: IProps) => {
  name = name ?? [];

  const { form } = useForm();

  return (
    <>
      <Form.Item name={[...name, 'id']} hidden>
        <Input hidden />
      </Form.Item>
      <Form.Item
        label="Número do cartão de crédito"
        name={[...name, 'number']}
        rules={[
          {
            required: true,
            validator: (_, value) => {
              const fixed = creditCardMask.clean(value);
              if (!fixed) {
                return Promise.reject('Por favor, digite o número do cartão de crédito!');
              }

              if (fixed.length < 13 || !checkLuhn(fixed)) {
                return Promise.reject('Por favor, digite um número de cartão de crédito válido!');
              }

              return Promise.resolve();
            },
          },
        ]}
      >
        <Input
          prefix={<CreditCardOutlined style={{ color: '#B6BBC2' }} />}
          onChange={(e) => {
            const fixed = creditCardMask.apply(e.target.value);
            form.setFieldValue(['payments', ...(name as string[]), 'number'], fixed);

            form.validateFields(['payments', ...(name as string[]), 'number'], {
              recursive: true,
              dirty: true,
            });
          }}
          placeholder="Digite o número do cartão"
        />
      </Form.Item>

      <Row gutter={10}>
        <Col span={12}>
          <Form.Item
            label="Validade"
            name={[...name, 'expiration']}
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  const fixed = dateMask.clean(value);
                  if (!fixed) {
                    return Promise.reject('Por favor, digite a validade do cartão de crédito!');
                  }

                  const currentDay = new Date().getDate();
                  const [month, year] = [fixed.slice(0, 2), fixed.slice(2, 4)].map(Number);
                  const expiration = new Date(
                    `20${year}-${month.toString().padStart(2, '0')}-${currentDay.toString().padStart(2, '0')}`
                  );

                  if (!(expiration instanceof Date && !isNaN(expiration.getTime())) || expiration < new Date()) {
                    return Promise.reject('Por favor, digite uma data válida!');
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              prefix={<CalendarOutlined style={{ color: '#B6BBC2' }} />}
              onChange={(e) => {
                const fixed = dateMask.apply(e.target.value);
                form.setFieldValue(['payments', ...(name as string[]), 'expiration'], fixed);

                form.validateFields(['payments', ...(name as string[]), 'expiration'], {
                  recursive: true,
                  dirty: true,
                });
              }}
              placeholder="Digite a validade (MM/AA)"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={[...name, 'cvv']}
            label={
              <Tooltip
                title={<img src={imgcvv} />}
                overlayInnerStyle={{ margin: 0, width: 100, boxShadow: 'none', padding: 0 }}
              >
                <span className="mr-2">CVV</span>
                <QuestionCircleFilled style={{ color: '#000' }} />
              </Tooltip>
            }
            rules={[
              {
                required: true,
                message: 'Por favor, digite o CVV do cartão de crédito!',
              },
              {
                min: 3,
                message: 'Por favor, digite um CVV válido!',
              },
              {
                max: 4,
                message: 'Por favor, digite um CVV válido!',
              },
            ]}
          >
            <Input maxLength={4} prefix={<KeyOutlined style={{ color: '#B6BBC2' }} />} placeholder="Digite o CVV" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Nome impresso no cartão de crédito"
        name={[...name, 'holder']}
        rules={[
          {
            required: true,
            message: 'Por favor, digite o nome impresso no cartão de crédito!',
          },
        ]}
      >
        <Input
          prefix={<UserOutlined style={{ color: '#B6BBC2' }} />}
          placeholder="Digite o que impresso no cartão de crédito"
        />
      </Form.Item>

      <Installments name={name} />
    </>
  );
};

export default CreditCard;
