import styled from '@emotion/styled';
import { Card, Col, Row, Typography } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { ReactNode, memo } from 'react';
import { IStyledProp } from '../../../interfaces/IStyledProp';

const { Text } = Typography;

interface IProps extends IStyledProp {
    name: string | ReactNode;
}

const CardSuccess = memo<IProps>(({ className, name }) => {

    return (
        <Card className={`${className} mx-2 mt-2`} title={name}>
            <Row>
                <Col>
                    <Text className='text-lg font-bold  text-gray-500'>
                        <CheckOutlined style={{ color: '#15803D', fontSize: 18, marginRight: 5 }} />
                        Parabéns
                    </Text>
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col>
                    <Text className='text-sm  text-gray-500'>
                        Parabéns pela sua compra! 🎉🛒 Espero que você esteja satisfeito(a) com o que adquiriu. Seja um produto, serviço ou experiência, aproveite ao máximo!
                    </Text>
                </Col>
            </Row>
        </Card>
    )
});

export default styled(CardSuccess)``;