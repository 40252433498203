import { Result } from "antd";

export const NotFound = () => {
  return (
    <Result
      status="403"
      title="403"
      subTitle="Desculpe, você não está autorizado a acessar esta página."
    />
  );
};