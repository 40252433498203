import { useEffect, useState } from 'react';
import { ICheckout } from '../interfaces/ICheckout';
import { useSearchParams } from 'react-router-dom';

export const usePreview = (id: string, modeAs: string) => {
  const [searchParams] = useSearchParams();

  const [prewiewInit, setPreviewInit] = useState(false);
  const [prewiewData, setPreviewData] = useState<ICheckout>({
    id: 'preview',
    teamId: 'preview',
    items: [
      {
        description: 'Produto 1',
        details: 'Detalhes do produto 1',
        amount: 100,
        quantity: 1,
        code: '1',
        image: 'https://via.placeholder.com/150',
        type: 'main',
        recurrenceChargesQuantity: 1,
        recurrenceFrequency: 'month',
        recurrenceInterval: 1,
        billingType: 'single',
      },
    ],
    allowCreditCard: true,
    allowBoleto: true,
    allowPix: true,
    askAddress: true,
    allowAddPayment: true,
  });

  useEffect(() => {
    if (modeAs != 'preview') {
      return;
    }

    const data = searchParams.get('data');
    if (data) {
      const parsedData = JSON.parse(atob(data));
      setPreviewData(parsedData);
      return;
    }

    const handler = (event: MessageEvent) => {
      if (event.data.type != 'preview') {
        return;
      }

      setPreviewData(event.data.data);
    };
    window.addEventListener('message', handler);

    setPreviewInit(true);

    return () => {
      window.removeEventListener('message', handler);
    };
  }, [modeAs, id, searchParams]);

  useEffect(() => {
    if (prewiewInit) {
      return;
    }

    window.parent.postMessage(
      { type: 'ready' },
      {
        targetOrigin: '*',
      }
    );
  }, [prewiewInit]);

  return {
    prewiewData,
  };
};
