export const isValidCpf = (cpf: string): boolean => {
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf.length !== 11) {
    return false;
  }

  if (cpf === '00000000000') {
    return false;
  }

  let sum = 0;
  let rest;

  for (let i = 1; i <= 9; i++) {
    sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  }

  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }

  if (rest !== parseInt(cpf.substring(9, 10))) {
    return false;
  }

  sum = 0;

  for (let i = 1; i <= 10; i++) {
    sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  }

  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }

  if (rest !== parseInt(cpf.substring(10, 11))) {
    return false;
  }

  return true;
};

export const isValidCnpj = (cnpj: string): boolean => {
  cnpj = cnpj.replace(/[^\d]+/g, '');
  if (cnpj.length !== 14) {
    return false;
  }

  if (cnpj === '00000000000000') {
    return false;
  }

  let size = cnpj.length - 2;
  let numbers = cnpj.substring(0, size);
  const digits = cnpj.substring(size);
  let sum = 0;
  let pos = size - 7;

  for (let i = size; i >= 1; i--) {
    sum += parseInt(numbers.charAt(size - i)) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }

  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

  if (result !== parseInt(digits.charAt(0))) {
    return false;
  }

  size = size + 1;
  numbers = cnpj.substring(0, size);
  sum = 0;
  pos = size - 7;

  for (let i = size; i >= 1; i--) {
    sum += parseInt(numbers.charAt(size - i)) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }

  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

  if (result !== parseInt(digits.charAt(1))) {
    return false;
  }

  return true;
};

const sanitizeGtmId = (id: string): string => {
  return id.replace(/[^\w-]/g, '');
};

export const setupGoogleTagManager = (gtmId: string) => {
  const gtmIdPattern = /^GTM-\w+$/;
  if (!gtmIdPattern.test(gtmId)) {
    console.error('Invalid GTM ID');
    return false;
  }

  gtmId = sanitizeGtmId(gtmId);

  // Inicializa o dataLayer do GTM
  const initScript = document.createElement('script');
  initScript.innerHTML = 'window.dataLayer = window.dataLayer || [];';

  // Adiciona o script de inicialização do dataLayer ao começo do <head>
  document.head.insertBefore(initScript, document.head.firstChild);

  const gtmScript = document.createElement('script');
  gtmScript.innerHTML =
    `
  <!-- Google Tag Manager -->
    (function(w,d,s,l,i){
      w[l]=w[l]||[];
      w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),
          dl=l!='dataLayer'?'&l='+l:'';
      j.async=true;
      j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
      f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','` +
    gtmId +
    `');
    <!-- End Google Tag Manager -->
  `;

  // Adiciona o script ao <head> da página
  document.head.insertBefore(gtmScript, document.head.firstChild);

  // Script do Google Tag Manager (noscript)
  const noscript = document.createElement('noscript');
  const iframe = document.createElement('iframe');
  iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
  iframe.height = '0';
  iframe.width = '0';
  iframe.style.display = 'none';
  iframe.style.visibility = 'hidden';
  noscript.appendChild(iframe);

  // Adiciona o noscript ao final do <body> da página
  document.body.appendChild(noscript);

  return true;
};

// format phone mask with ddi
export const formatPhone = (phone: string): string => {
  phone = phone.replace(/[^\d]+/g, '');
  if (phone.length < 10) {
    return phone;
  }

  if (phone.length == 10) {
    return phone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  }

  if (phone.length == 11) {
    return phone.replace(/(\d{1})(\d{2})(\d{4})(\d{4})/, '+$1 ($2) $3-$4');
  }

  if (phone.length == 12) {
    return phone.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/, '+$1 ($2) $3-$4');
  }

  return phone.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, '+$1 ($2) $3-$4');
};
