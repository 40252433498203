import { Form, Button, Row, Col, Typography } from 'antd';
import CreditCard from './CreditCard';
import Boleto from './Boleto';
import Pix from './Pix';
import { useForm } from '../context';
import { useCallback } from 'react';
import { QrcodeOutlined, CreditCardOutlined, BarcodeOutlined } from '@ant-design/icons';
import { moneyMask } from '../helper';
import Input from '../../Input';
import CreditCardResumed from './CreditCardResumed';

const { Text } = Typography;

const PaymentMethod = ({ name, field, fields, index }: any) => {
  const { form, checkout, getTotal, paymentMethods } = useForm();
  const paymentMethod = Form.useWatch([...name, field.name, 'paymentMethod'], form);
  const creditCardId = Form.useWatch([...name, field.name, 'creditCard', 'id'], form);
  const payments = Form.useWatch(name, form);

  const canShowBoleto =
    paymentMethods.includes('boleto') &&
    (checkout.allowMultiplePaymentsMethods || (!checkout.allowMultiplePaymentsMethods && index === 0));
  const canShowPix =
    paymentMethods.includes('pix') &&
    (checkout.allowMultiplePaymentsMethods || (!checkout.allowMultiplePaymentsMethods && index === 0));
  const canShowCreditCard = paymentMethods.includes('credit_card');

  const hasOnlyOnePaymentMethodShown = [canShowBoleto, canShowPix, canShowCreditCard].filter(Boolean).length === 1;

  const changePaymentMethod = useCallback(
    (method: string) => {
      form.setFieldValue([...name, field.name, 'paymentMethod'], method);
    },
    [form, field.name, name]
  );

  return (
    <>
      {!hasOnlyOnePaymentMethodShown && (
        <Row className="pb-4 flex items-center justify-center">
          <Col className="flex items-center justify-center">
            <Text className="text-sm font-bold text-gray-500">SELECIONE UM TIPO DE PAGAMENTO</Text>
          </Col>
        </Row>
      )}

      <Form.Item
        {...field}
        name={[field.name, 'paymentMethod']}
        rules={[{ required: true, message: 'Selecione um tipo de pagamento' }]}
        hidden={hasOnlyOnePaymentMethodShown}
      >
        <Row gutter={10} className="flex items-center justify-center">
          {canShowCreditCard && (
            <Col span={8} className="flex items-center justify-center">
              <Button
                className="rounded-md"
                type={paymentMethod === 'credit_card' ? 'primary' : 'default'}
                icon={<CreditCardOutlined />}
                size="large"
                block
                onClick={() => changePaymentMethod('credit_card')}
              >
                Cartão
              </Button>
            </Col>
          )}
          {canShowPix && (
            <Col span={8} className="flex items-center justify-center">
              <Button
                className="rounded-md"
                type={paymentMethod === 'pix' ? 'primary' : 'default'}
                icon={<QrcodeOutlined />}
                size="large"
                block
                onClick={() => changePaymentMethod('pix')}
              >
                PIX
              </Button>
            </Col>
          )}
          {canShowBoleto && (
            <Col span={8} className="flex items-center justify-center">
              <Button
                className="rounded-md"
                type={paymentMethod === 'boleto' ? 'primary' : 'default'}
                icon={<BarcodeOutlined />}
                size="large"
                block
                onClick={() => changePaymentMethod('boleto')}
              >
                Boleto
              </Button>
            </Col>
          )}
        </Row>
      </Form.Item>

      <Form.Item name={[field.name, 'forceNewInstallments']} hidden>
        <Input hidden />
      </Form.Item>

      {fields.length > 1 && (
        <Form.Item
          label="Valor"
          name={[field.name, 'value']}
          rules={[
            {
              required: true,
              validator: async (_, value) => {
                if (!value) {
                  return Promise.reject('Por favor, digite o valor do pagamento');
                }

                const valueClean = moneyMask.clean(value);
                if (valueClean <= 0) {
                  return Promise.reject('O valor do pagamento deve ser maior que 0');
                }

                const total = getTotal();

                if (valueClean > total / 100.0) {
                  return Promise.reject('O valor do pagamento não pode ser maior que o total');
                }

                const paymentsTotal = payments.reduce(
                  (acc: number, payment: any) => acc + moneyMask.clean(payment.value),
                  0
                );

                if (total !== Math.round(paymentsTotal * 100)) {
                  return Promise.reject('O valor total dos pagamentos deve ser igual ao valor total da compra.');
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            prefix="R$"
            name="value"
            onChange={(e) => {
              const fixed = moneyMask.apply(e.target.value);
              form.setFieldValue([...name, field.name, 'value'], fixed);
            }}
            onBlur={(e) => {
              let valueClean = moneyMask.clean(e.target.value);
              const total = getTotal() / 100.0;
              const totalPayments = payments.reduce((acc: number, payment: any, index: number) => {
                if (index === field.name) {
                  return acc;
                }

                return acc + moneyMask.clean(payment.value);
              }, 0);

              const restValue = total - totalPayments;

              if (valueClean > restValue) {
                valueClean = restValue;
              }

              const fixed = moneyMask.apply(valueClean);

              form.setFieldValue([...name, field.name, 'value'], fixed);
              form.validateFields([...name, field.name], {
                recursive: true,
                dirty: true,
              });
            }}
          ></Input>
        </Form.Item>
      )}

      {paymentMethod === 'credit_card' && !creditCardId && <CreditCard name={[field.name, 'creditCard']} />}
      {paymentMethod === 'credit_card' && creditCardId && <CreditCardResumed name={[field.name, 'creditCard']} />}
      {paymentMethod === 'boleto' && <Boleto />}
      {paymentMethod === 'pix' && <Pix />}
    </>
  );
};

export default PaymentMethod;
