import { ConfigProvider, Input as InputAntd, InputProps, Select, SelectProps } from 'antd';
import ReactInputMask from 'react-input-mask';
import { SearchProps } from 'antd/lib/input';

const Input = (props: InputProps) => {
  return (
    <InputAntd
      size="large"
      className="text-lg rounded-md hover:border-gray-500 hover:shadow-none focus:border-gray-700 focus:shadow-none"
      {...props}
    />
  );
};

export interface MaskedProps {
  mask: string;
  maskChar?: string;
  formatChars?: object;
  alwaysShowMask?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
}

export interface MaskedInputProps extends InputProps, MaskedProps {}

Input.Masked = (props: MaskedInputProps) => {
  return (
    <ReactInputMask {...props}>
      {(inputProps: any) => <Input {...inputProps} disabled={props.disabled ? props.disabled : null} />}
    </ReactInputMask>
  );
};

export interface MaskedSearchProps extends SearchProps, MaskedProps {}

Input.MaskedSearch = (props: MaskedSearchProps) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: 16,
          controlHeight: 35,
        },
      }}
    >
      <ReactInputMask {...props}>
        {(inputProps: any) => <InputAntd.Search size="large" {...inputProps} />}
      </ReactInputMask>
    </ConfigProvider>
  );
};

Input.Search = (props: SearchProps) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: 16,
          controlHeight: 35,
        },
      }}
    >
      <InputAntd.Search size="large" {...props} />
    </ConfigProvider>
  );
};

Input.Select = (props: SelectProps) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: 16,
          controlHeight: 35,
        },
      }}
    >
      <Select size="large" {...props} />
    </ConfigProvider>
  );
};

export { Input };
export default Input;
