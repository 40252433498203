import { Row, Col, Space, Alert } from 'antd';

const TestAlert = () => {
  return (
    <Row className="mb-5">
      <Col span={24} className="flex justify-center">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Alert
            message="Ambiente de Teste"
            description="Esta é uma simulação de compra, nenhum valor será cobrado."
            type="warning"
            showIcon
          />
        </Space>
      </Col>
    </Row>
  );
};

export default TestAlert;
