import { memo, useState } from 'react';
import { IStyledProp } from '../../../interfaces/IStyledProp';
import styled from '@emotion/styled';
import { useForm } from '../context';
import { LockOutlined, WhatsAppOutlined, SecurityScanOutlined } from '@ant-design/icons';
import { Row, Col, Divider, Typography, Layout, Image, Modal, Button, FloatButton } from 'antd';
import { getCdnUrl } from '../helper';

const { Text, Link } = Typography;

const Footer = memo<IStyledProp>(({ className }) => {
  const { checkout } = useForm();
  const hasSomeContact = checkout?.companyEmail || checkout?.companyPhone;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [linkTerms, setLinkTerms] = useState('');

  const showModal = (param: string) => {
    setLinkTerms(param);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setLinkTerms('');
    setIsModalOpen(false);
  };

  return (
    <div className={className}>
      <Modal
        title={
          <Layout.Footer
            className="flex rounded-md h-0 m-6 items-center justify-center"
            style={{
              backgroundColor: checkout?.styleConfig?.footerBgColor ?? '#E5E5E5',
            }}
          >
            <Image className="" preview={false} src={getCdnUrl(checkout?.companyLogo)}></Image>
          </Layout.Footer>
        }
        open={isModalOpen}
        onCancel={handleCancel}
        style={{ minWidth: 320, maxWidth: 1200 }}
        width="100%"
        footer={[
          <Button className="rounded-md w-20" type="default" size="large" onClick={() => setIsModalOpen(false)}>
            OK
          </Button>,
        ]}
      >
        {checkout?.agreementTerms && checkout?.agreementTerms.type === 'text' ? (
          <span dangerouslySetInnerHTML={{ __html: checkout?.agreementTerms?.value }} />
        ) : (
          <iframe
            className="w-full"
            style={{
              height: '60vh',
              border: 'none',
            }}
            src={getCdnUrl(linkTerms)}
          />
        )}
      </Modal>

      <Row className="mt-4">
        <Col span={12} className="flex items-center justify-center">
          <LockOutlined style={{ color: '#57A805', fontSize: 16 }} />
          <Text className="pl-1 text-sm" type="success">
            Privacidade protegida
          </Text>
        </Col>
        <Col span={12} className="flex items-center justify-center">
          <SecurityScanOutlined style={{ color: '#57A805', fontSize: 16 }} />
          <Text className="pl-1 text-sm" type="success">
            Verificado e protegido
          </Text>
        </Col>
      </Row>
      {hasSomeContact && (
        <Row>
          <Col span={24} className="px-3">
            <Divider />
            <Typography.Title level={5}>Precisa de ajuda?</Typography.Title>
          </Col>
        </Row>
      )}
      {checkout?.companyEmail ? (
        <Row>
          <Col span={24} className="px-3">
            <Text className="text-sm text-gray-600">
              Dúvidas sobre o produto: <span className="font-bold">{checkout?.companyEmail}</span>
            </Text>
          </Col>
        </Row>
      ) : null}
      {checkout?.companyPhone ? (
        <Row>
          <Col span={20} className="px-3 py-2">
            <Link
              className="text-sm"
              href={`https://api.whatsapp.com/send?phone=${checkout?.companyPhone}`}
              target="_blank"
            >
              Preciso de ajuda para preencher esta tela
            </Link>
          </Col>
          <Col span={4} className="px-3 py-2">
            <FloatButton
              tooltip="Como posso ajudar?"
              type="primary"
              href={`https://api.whatsapp.com/send?phone=${checkout?.companyPhone}`}
              target="_blank"
              icon={<WhatsAppOutlined />}
            ></FloatButton>
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col span={24} className="px-3">
          <Divider className="my-5"></Divider>
        </Col>
      </Row>

      {checkout?.installmentFee ? (
        <Row>
          <Col span={24} className="px-3">
            <Text className="text-xs text-gray-600">
              * O valor parcelado possui acréscimo de {checkout?.installmentFee / 100}% a.m.
            </Text>
          </Col>
        </Row>
      ) : null}

      {checkout?.agreementTerms ? (
        <Row>
          <Col span={24} className="px-3 pb-5">
            <Text className="text-xs text-gray-600">
              Ao clicar em "Comprar Agora", eu declaro que li e concordo com os
              <Link
                className="text-xs ml-1"
                onClick={() =>
                  showModal(checkout?.agreementTerms?.type == 'text' ? '' : (checkout?.agreementTerms?.value as string))
                }
              >
                Termos de Uso, Política de Privacidade e Cookies{' '}
              </Link>
              e afirma que é maior de idade ou autorizado e acompanhado por um responsável legal.
            </Text>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col span={24} className="px-3 pb-5">
            <Text className="text-xs text-gray-600">
              Ao clicar em "Comprar Agora", eu declaro que li e concordo com o
              <Link className="text-xs ml-1" onClick={() => showModal('https://routerfy.com/termosuso')}>
                Termos de Uso
              </Link>
              ,
              <Link className="text-xs ml-1" onClick={() => showModal('https://routerfy.com/politicaprivacidade')}>
                Política de Privacidade
              </Link>{' '}
              e
              <Link className="text-xs ml-1" onClick={() => showModal('https://routerfy.com/politicacookies')}>
                Cookies{' '}
              </Link>
              e afirma que é maior de idade ou autorizado e acompanhado por um responsável legal.
            </Text>
          </Col>
        </Row>
      )}

      <Layout.Footer
        className="flex rounded-b-lg h-0 items-center justify-center"
        style={{
          backgroundColor: checkout?.styleConfig?.footerBgColor ?? '#E5E5E5',
        }}
      >
        <Image className="" preview={false} src={getCdnUrl(checkout?.companyLogo)}></Image>
      </Layout.Footer>
    </div>
  );
});

export default styled(Footer)``;
