import { ICheckout, ICheckoutInstallment } from '../interfaces/ICheckout';
import { API_URL, ENV } from '../settings';
import axios from 'axios';

class CheckoutService {
  public async mountAsTransaction(transactionId: string): Promise<ICheckout> {
    const { data } = await axios.get(`${API_URL}/checkout/mount/transaction/${transactionId}`);

    return data;
  }

  public async mountAsCheckout(checkoutId: string): Promise<ICheckout> {
    const { data } = await axios.get(`${API_URL}/checkout/mount/checkout/${checkoutId}`, {
      ...(ENV === 'development' && {
        params: {
          skipCache: true,
        },
      }),
    });

    return data;
  }

  public async pay(payload: any) {
    const { data } = await axios.post(`${API_URL}/checkout/pay`, payload);

    return data;
  }

  public async getResultByTransactionId(transactionId: string) {
    const { data } = await axios.get(`${API_URL}/checkout/result/${transactionId}`);

    return data;
  }

  public async getInstallments(data: any) {
    const { data: installments } = await axios.post<ICheckoutInstallment[]>(`${API_URL}/checkout/installments`, data);

    return installments;
  }
}

export const checkoutService = new CheckoutService();
