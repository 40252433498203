import { useForm } from '../../context';
import NationalAddress from './National';
import InternationalAddress from './International';

interface IProps {
  name?: string[];
}

const Address = ({ name }: IProps) => {
  name = name ?? [];

  const { isForeigner } = useForm();

  if (isForeigner) {
    return <InternationalAddress name={name} />;
  }

  return <NationalAddress name={name} />;
};

export default Address;
