import { Button, Card, Form, Typography } from 'antd';
import { useForm } from '../../context';
import { useCallback } from 'react';
import Installments from '../CreditCard/Installments';
import { CreditCardOutlined } from '@ant-design/icons';

const { Text } = Typography;

interface IProps {
  name?: (string | number)[];
}

const CreditCardResumed = ({ name }: IProps) => {
  name = name ?? [];

  const { form, ocbData } = useForm();

  const id = Form.useWatch(['payments', ...(name as string[]), 'id'], form);
  const creditCard = ocbData?.payments.find((payment) => (payment.id = id));

  const number = `${creditCard?.firstDigits} ***** ${creditCard?.lastDigits}`;
  const expiration = `${creditCard?.expirationMonth}/${creditCard?.expirationYear}`;
  const holderName = creditCard?.holderName ?? '';

  const handleResetCreditCard = useCallback(() => {
    const baseName = [...(name as string[])];
    baseName.pop();

    const payment = form.getFieldValue(['payments', ...(baseName as string[])]);

    form.setFieldValue(['payments', ...(baseName as string[])], {
      paymentMethod: payment.paymentMethod,
      value: payment.value,
      forceNewInstallments: true,
    });
  }, [form, name]);

  return (
          <Card type="inner" title={<span><CreditCardOutlined style={{ fontSize: 14 }} /> Último cartão utilizado</span>} extra={<Button onClick={() => handleResetCreditCard()}>Usar outro cartão</Button>}>
            <Text>Titular: <strong>{holderName}{' - '}</strong></Text>
            <Text>Cartão: <strong>{number}{' - '}</strong></Text>
            <Text>Validade: <strong>{expiration}</strong></Text>
            <Installments name={name} />
          </Card>
        );
};

export default CreditCardResumed;
