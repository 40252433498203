function getEnv(env: string, defualtValue?: string) {
  return import.meta.env[`VITE_APP_${env}`] || defualtValue;
}

export const API_URL = getEnv('API_URL');
export const TRACK_API_URL = getEnv('TRACK_API_URL');
export const VERSION = '1.0.0';
export const ENV = getEnv('ENV', 'development');
export const SENTRY_DSN = getEnv('SENTRY_DSN');
export const SOCKET_URL = getEnv('SOCKET_URL');
export const CDN_URL = getEnv('CDN_URL');