import { Form } from 'antd';
import { useInstallments } from '../../../../../hooks/useInstallments';
import Input from '../../../../Input';
import { useForm } from '../../../context';

interface IProps {
  name?: (string | number)[];
}

const Installments = ({ name }: IProps) => {
  name = name ?? [];

  const { form, isRecurrence, items } = useForm();
  const { isLoading, options, data } = useInstallments(name);

  const hasEnrollmentFee = items.some((item) => item.type === 'enrollment_fee');

  return (
    <Form.Item
      className="mb-0 mt-5"
      label="Selecione o número de parcelas"
      name={[...name, 'installments']}
      hidden={isRecurrence && !hasEnrollmentFee}
    >
      <Input.Select
        disabled={isLoading}
        size="large"
        options={options}
        placeholder="Selecione o número de parcelas"
        onChange={(value) => {
          const installments = data?.find((installment: any) => installment.installment == value);
          form.setFieldValue(['payments', ...(name as string[]), 'installments'], Number(value));
          form.setFieldValue(['payments', ...(name as string[]), 'installmentValue'], installments?.value);
          form.setFieldValue(['payments', ...(name as string[]), 'installmentHash'], installments?.hash);
        }}
      />
    </Form.Item>
  );
};

export default Installments;
