import { memo } from 'react';
import { IStyledProp } from '../../../interfaces/IStyledProp';
import styled from '@emotion/styled';
import { useForm } from '../context';
import { getCdnUrl } from '../helper';
import { Button, Card, Switch } from 'antd';
import Meta from 'antd/es/card/Meta';
import { ShopOutlined, CheckSquareOutlined } from '@ant-design/icons';

const OrderBump = memo<IStyledProp>(({ className }) => {
  const { checkout, items, addItems, removeItems } = useForm();

  const bumpItems = checkout?.items?.filter((item) => item.type == 'order_bump') ?? [];

  if (!bumpItems.length) {
    return null;
  }
  const orderBumpTitle = checkout?.orderBumpTitle;
  const orderBumpSubtitle = checkout?.orderBumpSubtitle;

  return (
    <div className={`${className}`}>
      <Card className="bg-gray-100" style={{ border: '1px solid #9CA3AF' }} bodyStyle={{ padding: 12 }}>
        <Meta
          avatar={<ShopOutlined style={{ color: '#15803D', fontSize: 24 }} />}
          title={<span className="whitespace-pre-wrap">{orderBumpTitle}</span>}
          description={<span className="text-gray-900">{orderBumpSubtitle}</span>}
        />

        {bumpItems.map((item) => (
          <Card
            style={{
              border: '1px solid #059669',
              backgroundColor: `${items.some((i) => i.code === item.code) ? '#ECFDF5' : '#F7FCFA'}`,
            }}
            hoverable
            className={`w-100 mt-3 p-0`}
          >
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-4 flex justify-center items-center">
                <img src={getCdnUrl(item.image)} alt={item.description} className="w-20 h-20 rounded-lg" />
              </div>
              <div className="col-span-8">
                <Meta
                  title={<span className="whitespace-pre-wrap">{item.description}</span>}
                  description={<span className="text-gray-600">{item.details?.slice(0, 100)}</span>}
                />
                <p className="mt-1">
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(item.amount / 100)}
                </p>
                <p className="mt-1">
                  <Switch
                    checkedChildren="Adicionado"
                    unCheckedChildren="Adicionar"
                    checked={items.some((i) => i.code == item.code)}
                    onChange={(checked) => {
                      if (checked) {
                        addItems([item]);
                      } else {
                        removeItems([item]);
                      }
                    }}
                  />
                </p>
              </div>
            </div>
          </Card>
        ))}

        <div className="mt-3">
          <Button
            className="rounded-md mt-2"
            type="default"
            icon={<CheckSquareOutlined />}
            size="large"
            block
            onClick={() => addItems(bumpItems)}
          >
            ADICIONAR TODAS AS OFERTAS
          </Button>
        </div>
      </Card>
    </div>
  );
});

export default styled(OrderBump)`
  .ant-typography {
    margin-bottom: 0;
  }

  margin-top: 20px;
`;
