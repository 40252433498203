import { Row, Col, Typography } from "antd";

const { Text } = Typography;

import { ClockCircleOutlined, QrcodeOutlined, CalendarOutlined } from '@ant-design/icons';

const Boleto = () => {

  return (
    <>
      <Row gutter={10} className='justify-center grid grid-cols-3'>
        <Col className='border mx-1 border-gray-300 rounded-md'>
          <Row>
            <Col>
              <ClockCircleOutlined className="my-2" style={{ color: '#15803D', fontSize: 16 }} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Text className=' text-gray-800 text-sm font-bold'>Pague até a data de vencimento</Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text className='text-sm text-gray-400 '>Pague até o vencimento para garantir acesso ao produto.</Text>
            </Col>
          </Row>
        </Col>

        <Col className='border mx-1 border-gray-300 rounded-md'>
          <Row>
            <Col>
              <CalendarOutlined className="my-2" style={{ color: '#15803D', fontSize: 16 }} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Text className=' text-gray-800 text-sm font-bold'>Aguarde a aprovação do pagamento</Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text className='text-sm text-gray-400 '>Boletos podem leva <span className="font-bold">até 3 dias úteis</span> para serem compensados.</Text>
            </Col>
          </Row>
        </Col>

        <Col className='border mx-1 border-gray-300 rounded-md'>
          <Row>
            <Col>
              <QrcodeOutlined className="my-2" style={{ color: '#15803D', fontSize: 16 }} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Text className=' text-gray-800 text-sm font-bold'>Pague com Pix e tenha acesso imediato</Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text className='text-sm text-gray-400 '>Pagamentos são processados em alguns segundos.</Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Boleto;
