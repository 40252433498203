import { memo, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IStyledProp } from '../../interfaces/IStyledProp';
import Form from '../../components/Form';

interface ICheckoutProps extends IStyledProp {
  modeAs: 'checkout' | 'transaction' | 'preview';
}

const Checkout = memo<ICheckoutProps>(({ modeAs, className }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (!id && modeAs !== 'preview') {
      navigate('/');
      return;
    }
  }, [id, navigate]);

  if (!id && modeAs !== 'preview') {
    return null;
  }

  return (
    <div className={className} style={{ display: 'flex', padding: 10, backgroundColor: '#EBEFF4' }}>
        <Form className="px-3" id={id!} modeAs={modeAs} />
    </div>
  );
});

export default Checkout;