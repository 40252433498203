import Lottie from 'react-lottie';
import cartAnimation from '../../../assets/lotties/cart22.json'
import { useForm } from "../context";
import { memo, useEffect, useState } from "react";
import { IStyledProp } from "../../../interfaces/IStyledProp";
import { Col, Modal, Row, Typography } from 'antd';
import styled from '@emotion/styled';

const messages = [
  'Aguarde um momento, estamos processando seu pagamento.',
  'Estamos quase lá, aguarde mais um pouco.',
  'Estamos finalizando seu pagamento, aguarde.',
  'Seu pagamento está sendo processado, aguarde um momento.',
  'Estamos processando seu pagamento, aguarde um instante.',
];

interface IProps extends IStyledProp { }

const PayingModal = memo<IProps>(({ className }) => {
  const { isPaying } = useForm();
  const [currentMessage, setCurrentMessage] = useState(0);

  useEffect(() => {
    if (!isPaying) {
      return;
    }

    const interval = setInterval(() => {
      setCurrentMessage((prev) => (prev + 1) % messages.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);


  if (!isPaying) {
    return null;
  }

  return (
    <Modal
      closable={false}
      open={true}
      footer={null}
      centered
    >
      <Row justify="center" className={className}>
        <Col span={24}>
          <Lottie options={{
            loop: true,
            autoplay: true,
            animationData: cartAnimation,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          }} width={160}/>
        </Col>
        <Col span={24} className='messages'>
          <Typography.Text className='anim text-lg text-gray-500 font-bold'>{messages[currentMessage]}</Typography.Text>
        </Col>
      </Row>
    </Modal>
  );
});

export default styled(PayingModal)`
  .lottie-container {
    margin: 0 auto;
  }

  .messages {
    text-align: center;
    margin-top: 10px;
    align-items: center;
    animation: fadeIn 1s;
  }

  .anim {
    animation: fadeIn 1s;
  }
  

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;