import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import { NotFound } from './pages/NotFound';
import Checkout from './pages/Checkout';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Success from './pages/Success';
import { ConfigProvider } from 'antd';
import { IConfigStyle } from './interfaces/IConfigStyle';
import AppProvider from './context';
import { useState } from 'react';

const queryClient = new QueryClient();

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/t/:id" element={<Checkout modeAs="transaction" />} />
      <Route path="/t/:transactionId/success" element={<Success />} />
      <Route path="/c/:id" element={<Checkout modeAs="checkout" />} />
      <Route path="/c/:id/success/:transactionId" element={<Success />} />
      <Route path="/preview" element={<Checkout modeAs="preview" />} />
      <Route path="*" element={<NotFound />} />
    </>
  )
);

function App() {
  const [style, setStyle] = useState<IConfigStyle | undefined>({
    primaryColor: '#0AB187',
    secondaryColor: '#0AB187',
    borderColor: '#d9d9d9',
    textColor: '#4B5563',
    bgColor: '#ffffff',
    footerBgColor: '#E5E5E5',
  });

  return (
    <AppProvider value={{ style, setStyle }}>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider
          theme={{
            token: {
              borderRadius: 4,
              ...(style?.primaryColor ? { colorPrimary: style?.primaryColor } : {}),
              ...(style?.bgColor ? { colorBgContainer: style?.bgColor, colorBorder: style?.bgColor } : {}),
              ...(style?.secondaryColor
                ? { colorPrimaryHover: style?.secondaryColor, colorPrimaryActive: style?.secondaryColor }
                : {}),
            },
          }}
        >
          <RouterProvider router={router} />
        </ConfigProvider>
      </QueryClientProvider>
    </AppProvider>
  );
}

export default App;
