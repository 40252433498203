import { Form, Row, Col } from 'antd';
import Input from '../../../../Input';
import { HomeOutlined } from '@ant-design/icons';
import { countries } from './countries';
import { useMemo } from 'react';

interface IProps {
  name?: string[];
}

const InternationalAddress = ({ name }: IProps) => {
  name = name ?? [];

  const filterOption = (input: string, option: any) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const mappedCountries = useMemo(() => {
    return countries.map((country) => ({
      label: country.name,
      value: country.code,
    }));
  }, []);

  return (
    <>
      <Row gutter={10}>
        <Col span={12}>
          <Form.Item
            label="Postal Code"
            name={[...name, 'postalCode']}
            rules={[
              {
                required: true,
                message: 'Por favor, digite o seu Postal Code!',
              },
            ]}
          >
            <Input placeholder="Digite seu Postal Code" prefix={<HomeOutlined style={{ color: '#B6BBC2' }} />} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="País"
            name={[...name, 'country']}
            rules={[
              {
                required: true,
                message: 'Por favor, selecione seu país!',
              },
            ]}
          >
            <Input.Select
              showSearch
              placeholder="Selecione um país"
              optionFilterProp="children"
              filterOption={filterOption}
              options={mappedCountries}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Endereço"
        name={[...name, 'street']}
        rules={[
          {
            required: true,
            message: 'Por favor, digite seu endereço!',
          },
        ]}
      >
        <Input placeholder="Digite seu endereço" />
      </Form.Item>

      <Row gutter={10}>
        <Col span={12}>
          <Form.Item
            label="Cidade"
            name={[...name, 'city']}
            rules={[{ required: true, message: 'Por favor, digite a cidade!' }]}
          >
            <Input placeholder="Digite sua cidade" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Estado"
            name={[...name, 'state']}
            rules={[
              {
                required: true,
                message: 'Por favor, digite o seu estado!',
              },
            ]}
          >
            <Input placeholder="Digite seu estado" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default InternationalAddress;
