import { Col, Form, Row, Space, Typography } from 'antd';
import { useForm } from '../../context';
import Input from '../../../Input';
import { isValidCnpj, isValidCpf } from '../../../../helpers/functions';

import { UserOutlined, MailOutlined, PhoneOutlined, FileProtectOutlined } from '@ant-design/icons';
import ddi from './ddi';
import { documentMask, phoneMask } from '../../helper';
import Link from 'antd/es/typography/Link';

interface IProps {
  name?: (string | number)[];
}

const BasicInfo = ({ name }: IProps) => {
  name = name ?? [];

  const { form, checkout, isForeigner, setForeignerMode, canChangeForeigner } = useForm();
  const email = Form.useWatch([...name, 'email'], form);
  const phoneDDI = Form.useWatch([...name, 'ddi'], form);

  // Filter `option.label` match the user type `input`
  const filterOption = (input: string, option: any) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <Form.Item
        label="Nome completo"
        name={[...name, 'name']}
        rules={[
          {
            required: true,
            validator: (_, value: string) => {
              if (!value) {
                return Promise.reject('Por favor, digite seu nome completo!');
              }

              const values = value?.split(' ').filter((value) => value);
              if (values.length >= 2) {
                return Promise.resolve();
              }

              return Promise.reject('Por favor, digite seu nome completo!');
            },
          },
        ]}
      >
        <Input placeholder="Digite seu nome completo" prefix={<UserOutlined style={{ color: '#B6BBC2' }} />} />
      </Form.Item>
      <Form.Item
        label="E-mail"
        name={[...name, 'email']}
        rules={[
          {
            required: true,
            message: 'Por favor, digite seu e-mail!',
          },
          {
            type: 'email',
            message: 'Por favor, digite um e-mail válido!',
          },
        ]}
      >
        <Input type="email" prefix={<MailOutlined style={{ color: '#B6BBC2' }} />} placeholder="Digite seu e-mail" />
      </Form.Item>
      {checkout?.askConfirmEmail && (
        <Form.Item
          label="Confirme seu e-mail"
          name={[...name, 'confirmEmail']}
          rules={[
            {
              required: true,
              message: 'Por favor, digite seu e-mail!',
            },
            {
              type: 'email',
              message: 'Por favor, digite um e-mail válido!',
            },
            {
              validator: (_, value) => {
                if (value !== email) {
                  return Promise.reject('Os e-mails não coincidem!');
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input type="email" prefix={<MailOutlined style={{ color: '#B6BBC2' }} />} placeholder="Digite seu e-mail" />
        </Form.Item>
      )}
      <Form.Item
        label={
          <Space>
            <Typography.Text>{isForeigner ? 'Documento' : 'CPF/CNPJ'}</Typography.Text>
            {canChangeForeigner && (
              <Link onClick={() => setForeignerMode(!isForeigner)}>
                {isForeigner ? 'Não sou estrangeiro' : 'Sou estrangeiro'}
              </Link>
            )}
          </Space>
        }
        name={[...name, 'document']}
        rules={[
          {
            required: true,
            validator: (_, value) => {
              const label = isForeigner ? 'Documento' : 'CPF/CNPJ';

              if (!value) {
                return Promise.reject(`Por favor, digite o seu ${label}!`);
              }

              if (isForeigner) {
                if (value.length < 5) {
                  return Promise.reject('Por favor, digite um documento válido!');
                }

                if (value.length > 20) {
                  return Promise.reject('Por favor, digite um documento válido!');
                }

                return Promise.resolve();
              }

              const document = documentMask.clean(value);
              if (document.length <= 11 && !isValidCpf(document)) {
                return Promise.reject('Por favor, digite um CPF válido!');
              }

              if (document.length > 11 && !isValidCnpj(document)) {
                return Promise.reject('Por favor, digite um CNPJ válido!');
              }

              return Promise.resolve();
            },
          },
        ]}
      >
        <Input
          prefix={<FileProtectOutlined style={{ color: '#B6BBC2' }} />}
          placeholder={isForeigner ? 'Digite seu documento' : 'Digite o número do seu CPF/CNPJ'}
          onChange={(e) => {
            if (isForeigner) {
              return;
            }

            const fixed = documentMask.apply(e.target.value);
            form.setFieldValue([...(name as string[]), 'document'], fixed);

            form.validateFields([...(name as string[]), 'document'], {
              recursive: false,
              dirty: true,
            });
          }}
        />
      </Form.Item>

      <Row gutter={10}>
        <Col span={12}>
          <Form.Item
            label="DDI"
            name={[...name, 'ddi']}
            rules={[
              {
                required: true,
                message: 'Por favor, selecione seu DDI!',
              },
            ]}
          >
            <Input.Select
              showSearch
              placeholder="Selecione um DDI"
              optionFilterProp="children"
              filterOption={filterOption}
              options={ddi}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Celular"
            name={[...name, 'phone']}
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject('Por favor, digite seu celular!');
                  }

                  const phone = value?.replace(/\D/g, '');
                  if (phoneDDI != 'BR55' && phone.length >= 5) {
                    return Promise.resolve();
                  }

                  if (phone.length < 10) {
                    return Promise.reject('Por favor, digite um celular válido!');
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              prefix={<PhoneOutlined style={{ color: '#B6BBC2' }} />}
              {...(phoneDDI === 'BR55' && { placeholder: '(99) 9999-9999' })}
              onChange={(e) => {
                if (phoneDDI == 'BR55') {
                  const fixed = phoneMask.apply(e.target.value);
                  form.setFieldValue([...(name as string[]), 'phone'], fixed);
                }

                if (phoneDDI != 'BR55') {
                  const fixed = e.target.value.replace(/\D/g, '');
                  form.setFieldValue([...(name as string[]), 'phone'], fixed);
                }

                form.validateFields([...(name as string[]), 'phone'], {
                  recursive: false,
                  dirty: true,
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default BasicInfo;
