import { Divider, Form, Typography } from 'antd';
import { useForm } from '../../context';
import Input from '../../../Input';

const { Text, Link } = Typography;

interface IProps {
  name?: (string | number)[];
}

const BasicInfoResumed = ({ name }: IProps) => {
  name = name ?? [];

  const { isOCB, ocbData, disableOCB, checkout } = useForm();

  let customer = checkout?.customer;
  if (ocbData?.customer) {
    customer = ocbData.customer;
  }

  if (!customer) {
    return null;
  }

  return (
    <>
      <p>
        <Text>
          Olá, <strong>{customer.name}!</strong> Que bom ter você de volta por aqui!
        </Text>
      </p>
      {isOCB && (
        <p>
          <Text>
            <Link onClick={() => disableOCB()}>Clique aqui</Link>, caso não seja você.
          </Text>
        </p>
      )}
      <Form.Item name={[...name, 'id']} hidden>
        <Input hidden />
      </Form.Item>

      <Divider />
    </>
  );
};

export default BasicInfoResumed;
