import { createContext, useContext } from 'react';
import { IConfigStyle } from './interfaces/IConfigStyle';

type AppContext = {
  style?: IConfigStyle;
  setStyle: (style?: IConfigStyle) => void;
};

const AppContext = createContext<AppContext>({} as AppContext);

export function useAppContext() {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error('useAppContext must be used within a AppProvider');
  }

  return context;
}

const AppProvider = AppContext.Provider;

export default AppProvider;
