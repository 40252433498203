const ddi = [
    { value: 'AF93', label: '(+93) Afeganistão' },
    { value: 'ZA27', label: '(+27) África do Sul' },
    { value: 'AL355', label: '(+355) Albânia' },
    { value: 'DE49', label: '(+49) Alemanha' },
    { value: 'AD376', label: '(+376) Andorra' },
    { value: 'AO244', label: '(+244) Angola' },
    { value: 'AI1', label: '(+1) Antígua e Barbuda' },
    { value: 'AN599', label: '(+599) Antilhas Holandesas' },
    { value: 'SA966', label: '(+966) Arábia Saudita' },
    { value: 'DZ213', label: '(+213) Argélia' },
    { value: 'AR54', label: '(+54) Argentina' },
    { value: 'AM374', label: '(+374) Armênia' },
    { value: 'AW297', label: '(+297) Aruba' },
    { value: 'AF247', label: '(+247) Ilha de Ascensão' },
    { value: 'AU61', label: '(+61) Austrália' },
    { value: 'AT43', label: '(+43) Áustria' },
    { value: 'AZ994', label: '(+994) Azerbaijão' },
    { value: 'BS1', label: '(+1) Bahamas' },
    { value: 'BD880', label: '(+880) Bangladesh' },
    { value: 'BB1', label: '(+1) Barbados' },
    { value: 'BH973', label: '(+973) Bahrein' },
    { value: 'BE32', label: '(+32) Bélgica' },
    { value: 'BZ501', label: '(+501) Belize' },
    { value: 'BJ229', label: '(+229) Benim' },
    { value: 'BM1', label: '(+1) Bermudas' },
    { value: 'BY375', label: '(+375) Bielorrússia' },
    { value: 'BO591', label: '(+591) Bolívia' },
    { value: 'BA387', label: '(+387) Bósnia e Herzegovina' },
    { value: 'BW267', label: '(+267) Botswana' },
    { value: 'BR55', label: '(+55) Brasil' },
    { value: 'BN673', label: '(+673) Brunei' },
    { value: 'BG359', label: '(+359) Bulgária' },
    { value: 'BF226', label: '(+226) Burkina Faso' },
    { value: 'BI257', label: '(+257) Burundi' },
    { value: 'BT975', label: '(+975) Butão' },
    { value: 'CV238', label: '(+238) Cabo Verde' },
    { value: 'CM237', label: '(+237) Camarões' },
    { value: 'KH855', label: '(+855) Camboja' },
    { value: 'CA1', label: '(+1) Canadá' },
    { value: 'KZ7', label: '(+7) Cazaquistão' },
    { value: 'TD235', label: '(+235) Chade' },
    { value: 'CL56', label: '(+56) Chile' },
    { value: 'CN86', label: '(+86) República Popular da China' },
    { value: 'CY357', label: '(+357) Chipre' },
    { value: 'CO57', label: '(+57) Colômbia' },
    { value: 'KM269', label: '(+269) Comores' },
    { value: 'CG242', label: '(+242) Congo-Brazzaville' },
    { value: 'CD243', label: '(+243) Congo-Kinshasa' },
    { value: 'KP850', label: '(+850) Coreia do Norte' },
    { value: 'KR82', label: '(+82) Coreia do Sul' },
    { value: 'CI225', label: '(+225) Costa do Marfim' },
    { value: 'CR506', label: '(+506) Costa Rica' },
    { value: 'HR385', label: '(+385) Croácia' },
    { value: 'CU53', label: '(+53) Cuba' },
    { value: 'DK45', label: '(+45) Dinamarca' },
    { value: 'DJ253', label: '(+253) Djibuti' },
    { value: 'DM1', label: '(+1) Dominica' },
    { value: 'EG20', label: '(+20) Egito' },
    { value: 'SV503', label: '(+503) El Salvador' },
    { value: 'AE971', label: '(+971) Emirados Árabes Unidos' },
    { value: 'EC593', label: '(+593) Equador' },
    { value: 'ER291', label: '(+291) Eritreia' },
    { value: 'SK421', label: '(+421) Eslováquia (Slovakia)' },
    { value: 'SI386', label: '(+386) Eslovénia (Slovenia)' },
    { value: 'ES34', label: '(+34) Espanha' },
    { value: 'US1', label: '(+1) Estados Unidos (EUA)' },
    { value: 'EE372', label: '(+372) Estónia' },
    { value: 'ET251', label: '(+251) Etiópia' },
    { value: 'FJ679', label: '(+679) Fiji' },
    { value: 'PH63', label: '(+63) Filipinas' },
    { value: 'FI358', label: '(+358) Finlândia' },
    { value: 'FR33', label: '(+33) França' },
    { value: 'GA241', label: '(+241) Gabão' },
    { value: 'GM220', label: '(+220) Gâmbia' },
    { value: 'GH233', label: '(+233) Gana' },
    { value: 'GE995', label: '(+995) Geórgia' },
    { value: 'GI350', label: '(+350) Gibraltar' },
    { value: 'GD1', label: '(+1) Granada' },
    { value: 'GR30', label: '(+30) Grécia' },
    { value: 'GL299', label: '(+299) Groenlândia' },
    { value: 'BL590', label: '(+590) São Bartolomeu (Saint Barthelemy)' },
    { value: 'GU671', label: '(+671) Guam' },
    { value: 'GT502', label: '(+502) Guatemala' },
    { value: 'GY592', label: '(+592) Guiana' },
    { value: '594', label: '(+594) Guiana Francesa' },
    { value: 'GN224', label: '(+224) Guiné' },
    { value: 'GW245', label: '(+245) Guiné-Bissau' },
    { value: 'GQ240', label: '(+240) Guiné Equatorial' },
    { value: 'HT509', label: '(+509) Haiti' },
    { value: 'HN504', label: '(+504) Honduras' },
    { value: 'HK852', label: '(+852) Hong Kong' },
    { value: 'HU36', label: '(+36) Hungria' },
    { value: 'YE967', label: '(+967) Iémen (Iémen, Yemen)' },
    { value: 'KY1', label: '(+1) Ilhas Cayman' },
    { value: 'CX61', label: '(+61) Ilha Christmas (Christmas Island)' },
    { value: 'CC61', label: '(+61) Ilhas Cocos (Keeling)' },
    { value: 'CK682', label: '(+682) Ilhas Cook' },
    { value: 'FO298', label: '(+298) Ilhas Féroe' },
    { value: 'MV960', label: '(+960) Maldivas' },
    { value: 'FK500', label: '(+500) Ilhas Malvinas (Falkland)' },
    { value: 'MP1', label: '(+1) Ilhas Marianas do Norte' },
    { value: 'MH692', label: '(+692) Ilhas Marshall' },
    { value: 'NF672', label: '(+672) Ilha Norfolk' },
    { value: 'SB677', label: '(+677) Ilhas Salomão' },
    { value: 'VI1', label: '(+1) Ilhas Virgens Americanas' },
    { value: 'VG1', label: '(+1) Ilhas Virgens Britânicas' },
    { value: 'IN91', label: '(+91) Índia' },
    { value: 'ID62', label: '(+62) Indonésia' },
    { value: 'IR98', label: '(+98) Irã' },
    { value: 'IQ964', label: '(+964) Iraque' },
    { value: 'IE353', label: '(+353) Irlanda' },
    { value: 'IS354', label: '(+354) Islândia' },
    { value: 'IL972', label: '(+972) Israel' },
    { value: 'IT39', label: '(+39) Itália' },
    { value: 'JM1', label: '(+1) Jamaica' },
    { value: 'JP81', label: '(+81) Japão' },
    { value: 'JO962', label: '(+962) Jordânia' },
    { value: 'KI686', label: '(+686) Kiribati' },
    { value: '383', label: '(+383) Kosovo' },
    { value: 'KW965', label: '(+965) Kuwait' },
    { value: 'LA856', label: '(+856) Laos' },
    { value: 'LS266', label: '(+266) Lesoto' },
    { value: 'LV371', label: '(+371) Letônia' },
    { value: 'LB961', label: '(+961) Líbano' },
    { value: 'LR231', label: '(+231) Libéria' },
    { value: 'LY218', label: '(+218) Líbia' },
    { value: 'LI423', label: '(+423) Liechtenstein' },
    { value: 'LT370', label: '(+370) Lituânia' },
    { value: 'LU352', label: '(+352) Luxemburgo' },
    { value: 'MO853', label: '(+853) Macau' },
    { value: 'MK389', label: '(+389) República da Macedônia' },
    { value: 'MG261', label: '(+261) Madagascar' },
    { value: 'MY60', label: '(+60) Malásia' },
    { value: 'MW265', label: '(+265) Malawi' },
    { value: 'ML223', label: '(+223) Mali' },
    { value: 'MT356', label: '(+356) Malta' },
    { value: 'MA212', label: '(+212) Marrocos' },
    { value: '596', label: '(+596) Martinica' },
    { value: 'MU230', label: '(+230) Maurícia' },
    { value: 'MR222', label: '(+222) Mauritânia' },
    { value: 'YT262', label: '(+262) Mayotte' },
    { value: 'MX52', label: '(+52) México' },
    { value: 'FM691', label: '(+691) Micronésia (Estados Federados da Micronésia)' },
    { value: 'MZ258', label: '(+258) Moçambique' },
    { value: 'MD373', label: '(+373) Moldávia' },
    { value: 'MC377', label: '(+377) Mônaco' },
    { value: 'MN976', label: '(+976) Mongólia' },
    { value: 'ME382', label: '(+382) Montenegro' },
    { value: 'MS1', label: '(+1) Montserrat' },
    { value: 'MM95', label: '(+95) Myanmar (Burma)' },
    { value: 'NA264', label: '(+264) Namíbia' },
    { value: 'NR674', label: '(+674) Nauru' },
    { value: 'NP977', label: '(+977) Nepal' },
    { value: 'NI505', label: '(+505) Nicarágua' },
    { value: 'NE227', label: '(+227) Níger' },
    { value: 'NG234', label: '(+234) Nigéria' },
    { value: 'NU683', label: '(+683) Niue' },
    { value: 'NO47', label: '(+47) Noruega' },
    { value: 'NC687', label: '(+687) Nova Caledônia' },
    { value: 'NZ64', label: '(+64) Nova Zelândia' },
    { value: 'OM968', label: '(+968) Omã' },
    { value: 'NL31', label: '(+31) Países Baixos' },
    { value: '970', label: '(+970) Palestina (Banda de Gaza)' },
    { value: 'PW680', label: '(+680) Palau' },
    { value: 'PA507', label: '(+507) Panamá' },
    { value: 'PG675', label: '(+675) Papua-Nova Guiné' },
    { value: 'PK92', label: '(+92) Paquistão' },
    { value: 'PY595', label: '(+595) Paraguai' },
    { value: 'PE51', label: '(+51) Peru' },
    { value: 'PF689', label: '(+689) Polinésia Francesa' },
    { value: 'PL48', label: '(+48) Polônia' },
    { value: 'PR1', label: '(+1) Porto Rico' },
    { value: 'PT351', label: '(+351) Portugal' },
    { value: 'QA974', label: '(+974) Qatar (Catar)' },
    { value: 'KE254', label: '(+254) Quênia (Kenya)' },
    { value: 'KG996', label: '(+996) Quirguistão' },
    { value: 'GB44', label: '(+44) Reino Unido (Inglaterra)' },
    { value: 'CF236', label: '(+236) República Centro-Africana' },
    { value: 'DO1', label: '(+1) República Dominicana' },
    { value: 'CZ420', label: '(+420) República Tcheca (Tchéquia)' },
    { value: '262', label: '(+262) Reunião' },
    { value: 'RO40', label: '(+40) Romênia' },
    { value: 'RW250', label: '(+250) Ruanda' },
    { value: 'RU7', label: '(+7) Rússia' },
    { value: '212', label: '(+212) Saara Ocidental' },
    { value: 'WS685', label: '(+685) Samoa' },
    { value: 'AS1', label: '(+1) Samoa Americana' },
    { value: 'SH290', label: '(+290) Santa Helena (Saint Helena)' },
    { value: 'LC1', label: '(+1) Santa Lúcia (Saint Lucia)' },
    { value: 'KN1', label: '(+1) São Cristóvão e Nevis (Saint Kitts and Nevis)' },
    { value: 'SM378', label: '(+378) San Marino' },
    { value: 'PM508', label: '(+508) São Pedro e Miquelão (Saint-Pierre e Miquelon)' },
    { value: 'ST239', label: '(+239) São Tomé e Príncipe' },
    { value: 'VC1', label: '(+1) São Vicente e Granadinas (Saint Vincent and the Grenadines)' },
    { value: 'SC248', label: '(+248) Seicheles (Seychelles)' },
    { value: 'SN221', label: '(+221) Senegal' },
    { value: 'SL232', label: '(+232) Serra Leoa' },
    { value: 'RS381', label: '(+381) Sérvia (Serbia)' },
    { value: 'SG65', label: '(+65) Singapura' },
    { value: 'SY963', label: '(+963) Síria (Syria)' },
    { value: 'SO252', label: '(+252) Somália' },
    { value: 'LK94', label: '(+94) Sri Lanka' },
    { value: 'SZ268', label: '(+268) Essuatíni (Suazilândia)' },
    { value: 'SD249', label: '(+249) Sudão' },
    { value: '211', label: '(+211) Sudão do Sul' },
    { value: 'SE46', label: '(+46) Suécia' },
    { value: 'CH41', label: '(+41) Suíça' },
    { value: 'SR597', label: '(+597) Suriname' },
    { value: 'TJ992', label: '(+992) Tajiquistão' },
    { value: 'TH66', label: '(+66) Tailândia' },
    { value: 'TW886', label: '(+886) Taiwan (República da China)' },
    { value: 'TZ255', label: '(+255) Tanzânia' },
    { value: 'BB246', label: '(+246) Território Britânico do Oceano Índico (Barbados)' },
    { value: 'TL670', label: '(+670) Timor-Leste' },
    { value: 'TG228', label: '(+228) Togo' },
    { value: 'TK690', label: '(+690) Tokelau' },
    { value: 'TO676', label: '(+676) Tonga' },
    { value: 'TT1', label: '(+1) Trinidad e Tobago' },
    { value: 'TN216', label: '(+216) Tunísia' },
    { value: 'TC1', label: '(+1) Turcas e Caicos (Turks and Caicos Islands)' },
    { value: 'TM993', label: '(+993) Turcomenistão (Turquemenistão)' },
    { value: 'TR90', label: '(+90) Turquia' },
    { value: 'TV688', label: '(+688) Tuvalu' },
    { value: 'UA380', label: '(+380) Ucrânia' },
    { value: 'UG256', label: '(+256) Uganda' },
    { value: 'UY598', label: '(+598) Uruguai' },
    { value: 'UZ998', label: '(+998) Uzbequistão' },
    { value: 'VU678', label: '(+678) Vanuatu' },
    { value: 'VA379', label: '(+379) Vaticano (Cidade do Vaticano)' },
    { value: 'VE58', label: '(+58) Venezuela' },
    { value: 'VN84', label: '(+84) Vietnã' },
    { value: 'WF681', label: '(+681) Wallis e Futuna (Ilhas Wallis e Futuna)' },
    { value: 'ZM260', label: '(+260) Zâmbia' },
    { value: 'ZW263', label: '(+263) Zimbabwe(Zimbabué)' }

];

export default ddi;