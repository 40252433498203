import './sentry.ts';
import React, { ErrorInfo } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.tsx';
import * as Sentry from '@sentry/react';

declare global {
  interface Window {
    Routerfy: any;
    __data: string;
    dataLayer: any;
  }
}

ReactDOM.createRoot(document.getElementById('root')!, {
  onRecoverableError: (error: unknown, errorInfo: ErrorInfo) => {
    Sentry.captureException(error, {
      extra: {
        errorInfo,
      },
    });
  },
}).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);
