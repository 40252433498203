import { useEffect, useState } from 'react';
import { useForm } from '../components/Form/context';
import { Form } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { checkoutService } from '../services/CheckoutService';
import { moneyMask } from '../components/Form/helper';
import { useDebounce } from './useDebounce';

export const useInstallments = (name: (string | number)[]) => {
  name = name ?? [];
  const [firstValue, setFirstValue] = useState();
  const [valueDirty, setValueDirty] = useState(false);

  const parentName = name.slice(0, -1);
  const { checkout, form, isRecurrence, getTotal, items, isForeigner, isOCB } = useForm();
  const payments = Form.useWatch(['payments'], form);

  const value = Form.useWatch(['payments', ...parentName, 'value'], form);

  const hasEnrollmentFee = items.some((item) => item.type === 'enrollment_fee');

  const valueDebounced = useDebounce(value, 500);

  const total = getTotal();

  let { data, isLoading } = useQuery({
    queryKey: ['installments', ...name, valueDebounced],
    queryFn: () =>
      checkoutService.getInstallments({
        total: moneyMask.clean(value),
        maxInstallments: checkout?.maxInstallments,
        installmentFee: checkout?.installmentFee,
        installmentGeneratorHash: checkout?.installmentGeneratorHash,
      }),
    enabled:
      !!checkout &&
      !!value &&
      !!checkout.installmentGeneratorHash &&
      valueDirty &&
      moneyMask.clean(value) * 100 <= total,
  });

  data = valueDirty ? data : checkout?.installments;

  useEffect(() => {
    if (!data) return;

    const baseName = ['payments', ...(name as string[])];

    const currentInstallment = form.getFieldValue([...baseName, 'installments']);
    if (!currentInstallment) {
      const installment = data[isRecurrence && !hasEnrollmentFee ? 0 : data.length - 1];
      if (!installment) return;

      form.setFieldValue([...baseName, 'installments'], installment?.installment);
      form.setFieldValue([...baseName, 'installmentValue'], installment?.value);
      form.setFieldValue([...baseName, 'installmentHash'], installment?.hash);

      return;
    }

    let installment = data.find((installment: any) => installment.installment == currentInstallment);
    if (!installment) {
      installment = data[isRecurrence ? 0 : data.length - 1];
    }

    form.setFieldValue([...baseName, 'installments'], installment?.installment);
    form.setFieldValue([...baseName, 'installmentValue'], installment?.value);
    form.setFieldValue([...baseName, 'installmentHash'], installment?.hash);
  }, [data, isForeigner, isOCB]);

  useEffect(() => {
    if (!value) return;

    setFirstValue(value);
  }, [value]);

  useEffect(() => {
    if (!value) return;
    if (!firstValue) return;

    if (firstValue == value && payments.length == 1) return;

    setValueDirty(true);
  }, [value, firstValue, payments]);

  useEffect(() => {
    const forceNewInstallments = form.getFieldValue(['payments', ...parentName, 'forceNewInstallments']);
    if (forceNewInstallments && !valueDirty) {
      setValueDirty(true);
    }
  }, [parentName, valueDirty, payments]);

  const hasFee = (checkout?.installmentFee ?? 0) > 0;

  const options = data?.map((installment: any) => ({
    label: `${installment.installment}x de ${new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(installment.value)}${hasFee && installment.installment != 1 ? '*' : ''}`,
    value: installment.installment,
  }));

  return {
    options,
    isLoading,
    data,
  };
};
