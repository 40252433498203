import { memo } from 'react';
import { IStyledProp } from '../../../interfaces/IStyledProp';
import styled from '@emotion/styled';
import { SafetyOutlined } from '@ant-design/icons';
import { Row, Col, Select, Typography, Image } from 'antd';
import brandeirabrasil from '../../../assets/brasil.png';
//import brandeiraEUA from '../../../assets/estados-unidos.png';
//import bandeiraespanha from '../../../assets/espanha.png';

const { Text } = Typography;
const { Option } = Select;

const LanguageSelector = memo<IStyledProp>(({ className }) => {
  return (
    <Row gutter={5} className={`${className} flex justify-end p-3 pt-2 pb-2`}>
      <Col span={12} className="pt-1">
        <SafetyOutlined style={{ color: '#57A805', fontSize: 18 }} />
        <Text className="pl-1 text-base" type="success">
          Compra 100% segura
        </Text>
      </Col>
      <Col span={12} className="flex justify-end">
        <Select style={{ width: 140 }} defaultValue="1">
          <Option value="1">
            <Image className="my-1" preview={false} src={brandeirabrasil} width={18} height={18} /> Português
          </Option>
          {/*<Option value="2">
              <Image className="my-1" preview={false} src={brandeiraEUA} width={18} height={18} /> Inglês
            </Option>
            <Option value="3">
              <Image className="my-1" preview={false} src={bandeiraespanha} width={18} height={18} /> Espanhol
            </Option>*/}
        </Select>
      </Col>
    </Row>
  );
});

export default styled(LanguageSelector)``;
