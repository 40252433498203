import io, { Socket } from 'socket.io-client';
import { SOCKET_URL } from '../settings';

class SocketService {
  private conn!: Socket;
  private connected = false;

  public connect(data: { checkoutId: string; teamId: string }) {
    if (this.conn) {
      this.conn.disconnect();
    }

    this.conn = io(SOCKET_URL, {
      query: data,
      reconnection: true,
    });

    this.conn.on('connect', () => {
      this.connected = true;
    });

    this.conn.on('disconnect', () => {
      this.connected = false;
    });
  }

  public emit(event: string, data: any) {
    this.conn.emit(event, data);
  }

  public on(event: string, cb: (data: any) => void) {
    this.conn.on(event, cb);
  }

  public isConnected() {
    return this.connected;
  }

  public disconnect() {
    this.conn.disconnect();
  }

  public getConnection() {
    return this.conn;
  }
}

export const socketService = new SocketService();
