import { Row, Col, Typography } from "antd";

const { Text } = Typography;

import { ClockCircleOutlined, QrcodeOutlined, FileProtectOutlined } from '@ant-design/icons';


const Pix = () => {
  return <>
    <Row gutter={10} className='justify-center grid grid-cols-3'>
      <Col className='border mx-1 border-gray-300 rounded-md'>
        <Row>
          <Col>
            <ClockCircleOutlined className="my-2" style={{ color: '#15803D', fontSize: 16 }} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Text className=' text-gray-800 text-sm font-bold'>Aprovação imediata</Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <Text className='text-sm text-gray-400 '>O pagamento com Pix é processado rapidamente.</Text>
          </Col>
        </Row>
      </Col>

      <Col className='border mx-1 border-gray-300 rounded-md'>
        <Row>
          <Col>
            <FileProtectOutlined className="my-2" style={{ color: '#15803D', fontSize: 16 }} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Text className=' text-gray-800 text-sm font-bold'>Transação segura</Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <Text className='text-sm text-gray-400 '>O Pix simplifica suas compras e protege seus dados.</Text>
          </Col>
        </Row>
      </Col>

      <Col className='border mx-1 border-gray-300 rounded-md'>
        <Row>
          <Col>
            <QrcodeOutlined className="my-2" style={{ color: '#15803D', fontSize: 16 }} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Text className=' text-gray-800 text-sm font-bold'>Compre com facilidade</Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <Text className='text-sm text-gray-400 '>Acesse o app do banco, escaneie o QR code ou digite o código.</Text>
          </Col>
        </Row>
      </Col>
    </Row>

  </>;
};

export default Pix;