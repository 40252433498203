/* eslint-disable react-refresh/only-export-components */
import { Form, Typography, Button, Row, Col, Collapse } from 'antd';
import { useForm } from '../context';
import { ReactNode, memo, useEffect, useState } from 'react';
import { LockOutlined, QrcodeOutlined, CreditCardOutlined, BarcodeOutlined, PlusOutlined } from '@ant-design/icons';
import { IStyledProp } from '../../../interfaces/IStyledProp';
import styled from '@emotion/styled';
import PaymentMethod from './PaymentMethod';
import { moneyMask } from '../helper';

interface IProps extends IStyledProp {
  name?: string[];
}

const { Text } = Typography;

const Payment = memo<IProps>(({ className, name }) => {
  name = name ?? [];

  const { checkout, form, getTotal, items, isRecurrence, isOCB, ocbData, paymentMethods } = useForm();

  const [activeKeys, setActiveKeys] = useState<string[]>(['1']);

  const payments = Form.useWatch([...name, 'payments'], form);
  const lastPayment = payments?.[payments.length - 1];

  const hasEnrollmentFee = items.some((item) => item.type === 'enrollment_fee');

  const firstPaymentMethod = paymentMethods?.[0];

  useEffect(() => {
    if (!payments) {
      return;
    }

    if (payments.length === 1 && activeKeys[0] !== '1') {
      setActiveKeys(['1']);
    }
  }, [payments, activeKeys, setActiveKeys]);

  useEffect(() => {
    if (!isOCB) {
      return;
    }
    setActiveKeys(Array.from({ length: ocbData?.payments.length ?? 1 }, (_, i) => String(i + 1)));
  }, [ocbData, setActiveKeys, isOCB]);

  useEffect(() => {
    if (!payments) {
      return;
    }

    if (payments?.length <= 1) {
      return;
    }

    const total = getTotal();

    const firstPayment = payments[0];
    if (!checkout.allowMultiplePaymentsMethods && firstPayment.paymentMethod !== firstPaymentMethod) {
      form.setFieldValue(
        [...(name as string[]), 'payments'],
        [
          {
            paymentMethod: firstPayment.paymentMethod,
            value: total,
          },
        ]
      );
    }
  }, [payments, checkout, firstPaymentMethod, form, name]);

  useEffect(() => {
    if (!payments || payments?.length > 1) {
      return;
    }

    const total = getTotal() / 100.0;
    if (!total) {
      return;
    }

    form.setFieldValue([...(name as string[]), 'payments', 0, 'value'], moneyMask.apply(total));
  }, [payments, form, getTotal, items]);

  const allowAddNewPayment =
    (checkout.allowMultipleCards && lastPayment?.paymentMethod == 'credit_card') ||
    checkout.allowMultiplePaymentsMethods;

  return (
    <div className={className}>
      <Row className="flex items-center justify-center mb-3">
        <Col>
          <LockOutlined style={{ color: '#57A805', fontSize: 16 }} />
          <Text className="pl-1 text-sm" type="success">
            Seus dados serão mantidos em sigilo
          </Text>
        </Col>
      </Row>

      <Form.List name={[...name, 'payments']}>
        {(fields, { add, remove }) => {
          const handleOnChange = (key: string | string[]) => {
            setActiveKeys(key as string[]);
          };

          const buildPreviewPayment = (id: string, fieldName: (string | number)[]) => {
            const types = {
              credit_card: (extra?: string) => {
                const creditCard = form.getFieldValue([...fieldName, 'creditCard']);
                const lastDigits = (creditCard?.number ?? '')?.replace(/\D/g, '')?.substr(0, 16)?.slice(-4);
                const installments = creditCard?.installments;
                const installmentValue = creditCard?.installmentValue;

                const infos =
                  installments && (!isRecurrence || hasEnrollmentFee)
                    ? `(${installments}x de ${new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(installmentValue)})`
                    : '';

                return (
                  <div>
                    <CreditCardOutlined />{' '}
                    <Text>
                      Cartão {lastDigits} {infos} {extra}
                    </Text>
                  </div>
                );
              },
              boleto: (extra?: string) => {
                const value = moneyMask.clean(form.getFieldValue([...fieldName, 'value']));

                const infos = `(${new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(isNaN(value) ? 0 : value)})`;

                return (
                  <div>
                    <BarcodeOutlined />{' '}
                    <Text>
                      Boleto {extra != '' && infos} {extra}
                    </Text>
                  </div>
                );
              },
              pix: (extra?: string) => {
                const value = moneyMask.clean(form.getFieldValue([...fieldName, 'value']));

                const infos = `(${new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(isNaN(value) ? 0 : value)})`;

                return (
                  <div>
                    <QrcodeOutlined />{' '}
                    <Text>
                      PIX {extra != '' && infos} {extra}
                    </Text>
                  </div>
                );
              },
            } as {
              [key: string]: (extra?: string) => ReactNode;
            };

            const paymentMethod = form.getFieldValue([...fieldName, 'paymentMethod']);

            return types[paymentMethod](fields.length > 1 ? `#${id}` : '');
          };

          const handleOnRemovePayment = (index: number) => {
            remove(index);

            const newActiveKeys = activeKeys
              .map((key) => {
                const keyIndex = Number(key) - 1;
                if (keyIndex < 1) {
                  return key;
                }
                return keyIndex === index ? undefined : keyIndex;
              })
              .filter((key) => key !== undefined)
              .map((key) => String(key));

            setActiveKeys(newActiveKeys);
          };

          return (
            <>
              <Collapse
                className="bg-neutral-100"
                defaultActiveKey="1"
                activeKey={activeKeys}
                expandIconPosition="start"
                onChange={handleOnChange}
                items={fields.map((field, index) => {
                  const id = String(index + 1);
                  const fieldName = [...(name as string[]), 'payments'];

                  return {
                    label: <div key={Date.now()}>{buildPreviewPayment(id, [...fieldName, field.name])}</div>,
                    key: id,
                    collapsible: fields.length > 1 ? 'header' : 'header',
                    extra:
                      fields.length > 1 ? (
                        <Button
                          type="default"
                          onClick={(e) => {
                            e.stopPropagation();

                            handleOnRemovePayment(index);
                          }}
                        >
                          Remover
                        </Button>
                      ) : undefined,
                    children: (
                      <PaymentMethod key={field.key} name={fieldName} field={field} fields={fields} index={index} />
                    ),
                  };
                })}
              />

              {allowAddNewPayment && (
                <Button
                  className="rounded-md mt-2"
                  type="default"
                  icon={<PlusOutlined />}
                  size="large"
                  block
                  onClick={() => {
                    const lastValue = moneyMask.clean(lastPayment?.value ?? 0);
                    const value = lastValue / 2;

                    form.setFieldValue(
                      [...(name as string[]), 'payments', fields.length - 1, 'value'],
                      moneyMask.apply(value)
                    );

                    add({
                      paymentMethod: firstPaymentMethod,
                      value: moneyMask.apply(value),
                    });

                    setActiveKeys([...activeKeys, String(fields.length + 1)]);
                  }}
                >
                  ADICIONAR TIPO DE PAGAMENTO
                </Button>
              )}
            </>
          );
        }}
      </Form.List>
    </div>
  );
});

export default styled(Payment)`
  .ant-collapse-header {
    align-items: center !important;
    font-weight: bold !important;
  }
`;
